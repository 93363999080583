<template>
  <section class="content w-100">
    <div class="flex justify-content-center">
      <div class="w-100 card">
        <div class="card-body">
          <div class="row">
            <div class="col-6 col-md-6 col-lg-3">
              <div class="info-box">
                <span class="info-box-icon bg-info elevation-2"
                  ><i class="fas fa-bookmark"></i
                ></span>
                <div class="info-box-content w-100">
                  <h5 class="info-box-text">TOTAL</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.total_claim }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-6 col-md-6 col-lg-3">
              <div class="info-box">
                <span class="info-box-icon bg-warning elevati2n-1"
                  ><i class="fas fa-inbox"></i
                ></span>
                <div class="info-box-content w-100">
                  <h5 class="info-box-text">PENDING</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.pending }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-12 col-md-6 col-lg-3 py-1">
              <div class="info-box">
                <span class="info-box-icon bg-success elevati2n-1"
                  ><i class="fas fa-check-double"></i
                ></span>
                <div class="info-box-content w-100">
                  <h5 class="info-box-text">APPROVED</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.approve }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <div class="info-box">
                <span class="info-box-icon bg-danger elevatio2-1"
                  ><i class="fas fa-window-close"></i
                ></span>
                <div class="info-box-content w-100">
                  <h5 class="info-box-text">NOT APPROVED</h5>
                  <h5 class="info-box-content" v-bind:class="{ 'skeleton-item': loading }">{{ allTicket.not_approve }}</h5>
                </div>
                <!-- /.info-box-content -->
              </div>
              <!-- /.info-box -->
            </div>
          </div>
					<div class="row d-flex align-items-center">
            <div class="col-12 col-sm-auto py-1">
							<label class="control-label m-0">
								<h6 class="m-0">Filter Pencarian</h6>
							</label>
						</div>
            <div class="col-12 col-sm-auto py-1">
							<select class="form-control" @change="dataType($event)">
								<option value="createdOn">Dibuat Antara</option>
								<!-- <option value="lastTm">Update Antara</option> -->
							</select>
						</div>
            <div class="col-12 col-sm-auto py-1">
							<div class="input-group">
								<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
								<div class="input-group-append">
									<div class="input-group-text" style="background-color: #fff">
										<span><span class="fa fa-calendar"></span></span>
									</div>
								</div>
							</div>
						</div>
            <!-- <div class="col-auto">
							<select class="form-control" @change="ticketType($event)">
								<option value="" disabled selected>Status Ticket</option>
								<option value="open">Open</option>
								<option value="closed">Closed</option>
							</select>
						</div> -->
            <div class="col-auto py-1">
              <select id="claim" class="form-control" @change="claimType($event)">
                <option value="" disabled selected>Status Claim</option>
                <option value="pending">Pending</option>
                <option value="approve">Approved</option>
                <option value="not_approve">Not Approved</option>
                <option value="banding">Banding</option>
                <option value="void">Void</option>
                <option value="all">Semua</option>
              </select>
            </div>
            <div class="col-auto py-1" v-if="userInfo.role_id < 11">
              <select id="claim_courier" class="form-control" @change="claimCourierType($event)">
                <option value="" disabled selected>Status Claim (By Courier)</option>
                <option value="pending_courier">Pending</option>
                <option value="approve_courier">Approved</option>
                <option value="not_approve_courier">Not Approved</option>
                <option value="all_courier">Semua</option>
              </select>
            </div>
            <div class="col-auto py-1">
                <button class="btn btn-default bg-white" @click="selectStatus">{{ specialFilterLabel[filter.special] }} <i class="fi fi-rr-angle-small-down"></i></button>
            </div>
            <div class="col-auto ml-auto py-1" v-if="true">
              <div class="btn-group" ref="toolbar">
                <button type="button" data-action="read" class="btn btn-default"><i
                    class="fa fa-sync-alt"></i></button>
                <button type="button" :disabled="downloading" v-on:click="downloadXlsx"
                  data-action="export-xlsx" class="btn btn-default" v-if="true"><i class="fa fa-download"></i> <span class="d-none d-md-inline-block">Download</span></button>
              </div>
            </div>
          </div>
          <div class="btn-area pt-3" ref="btnSelected" style="display:none;">
            <button type="button" class="ml-2 btn btn-danger" @click="claimReturn" v-if="userInfo.role_id < 12"
              :disabled="false"><i class="fa fa-exclamation-circle"></i> Ubah Kategori</button>
            <button type="button" class="ml-2 btn btn-success" @click="closeClaimReturn" v-if="userInfo.role_id == 1 || userInfo.role_id == 2 || userInfo.role_id == 10"
              :disabled="false">Ubah Status</button>
            <button type="button" class="ml-2 btn btn-warning text-light" @click="closeClaimReturnCourier" v-if="userInfo.role_id == 2 || userInfo.role_id == 10"
              :disabled="false">Ubah Status Kurir</button>
          </div>
          <div class="table-ticket pt-3">
            <table class="table table-hover" ref="tblticket" id="tblticket">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1"
                      @click="selectAll" />
                  </th>
                  <th>AWB</th>
                  <th>JUDUL</th>
                  <th>PIC</th>
                  <th>KATEGORI CLAIM</th>
                  <th>STATUS CLAIM</th>
                  <th>SHIPPING DATE</th>
                  <th>PENGAJUAN DATE</th>
                  <th>DONE PROCESS</th>
                  <th>KOMENTAR</th>
                  <th>UMUR</th>
                  <th>KURIR</th>
                  <th>MARK</th>
                </tr>
              </thead>
              <tbody @click="handleClick"></tbody>
            </table>
          </div>
          <UbahKategori :show="openClaim" :awal="awalItems" :status="trackingStatus" v-model="selectedItems" v-on:change="reloadData" />
          <FormClosingClaim :show="showClosingClaim" :courier="returnClaimCourier" v-model="selectedItems" v-on:change="reloadData" />
          <FormClosingClaimCourier :show="showClosingClaimCourier" :awal="return_courier" v-model="selectedItems" v-on:change="reloadData" />
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" ref="formFilter" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form @submit.prevent="getSpecial($event)">
                    <div class="modal-header">
                        <h5 class="modal-title font-weight-bold">Filter Withdraw</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group m-2 p-3 bg-light rounded">
                            <div class="row">
                                <div class="col d-flex justify-content-between">
                                    <label class="h6">Status Special Claim Admin</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col">
                                    <div class="icheck-material-orange icheck-inline">
                                        <input type="radio" class="form-check-input" id="request" name="status-special" v-model="filter.special" value="1" />
                                        <label class="form-check-label w-100" for="request">Request</label>
                                    </div>
                                </div>
                                <div class="form-group col">
                                    <div class="icheck-material-orange icheck-inline">
                                        <input type="radio" class="form-check-input" id="approve" name="status-special" v-model="filter.special" value="2" />
                                        <label class="form-check-label w-100" for="approve">Approve</label>
                                    </div>
                                </div>
                                <div class="form-group col">
                                    <div class="icheck-material-orange icheck-inline">
                                        <input type="radio" class="form-check-input" id="reject" name="status-special" v-model="filter.special" value="3" />
                                        <label class="form-check-label w-100" for="reject">Reject</label>
                                    </div>
                                </div>
                                <div class="form-group col">
                                    <div class="icheck-material-orange icheck-inline">
                                        <input type="radio" class="form-check-input" id="banding" name="status-special" v-model="filter.special" value="4" />
                                        <label class="form-check-label w-100" for="banding">Banding</label>
                                    </div>
                                </div>
                                <div class="form-group col col-sm-4">
                                    <div class="icheck-material-orange icheck-inline">
                                        <input type="radio" class="form-check-input" id="all" name="status-special" v-model="filter.special" value="all" />
                                        <label class="form-check-label w-100" for="all">Semua</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Cari</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import 'jquery-ui';
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import UbahKategori from "@/dialog/UbahKategori";
import FormClosingClaim from "@/dialog/FormClosingClaim";
import FormClosingClaimCourier from "@/dialog/FormClosingClaimCourier";
import moment from "moment";
import "icheck-material";

export default {
  name: "Claim Admin",
  data() {
    return {
        dateStart: moment('2021-01-01').format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
        method: "",
        roles: "",
        allTicket: {},
        downloading: false,
        show: false,
        filter: {special : ''},
        form: {
        status: "",
        },
        dt1: moment('2021-01-01'),
        dt2: moment(),
        data_type: "createdOn",
        ticket_type: "",
        selectedItems: [],
        returnClaimCourier: false,
        awalItems: '-',
        return_courier: '',
        openClaim: false,
        showClosingClaim: false,
        showClosingClaimCourier: false,
        specialFilterLabel: {
          '' : 'Filter Special Claim',
          '1' : 'Request Special Claim',
          '2' : 'Approved Special Claim',
          '3' : 'Rejected Special Claim',
          '4' : 'Banding Special Claim',
          'all' : 'Tanpa Filter Special',
        },
        trackingStatus: 4,
        loading: false,
        userInfo: {},
    };
  },
  watch:{
    $route (to, from){
      if(to.path === "/arsip/klaim")
      {
        // sessionStorage.filterData = '';
		    // sessionStorage.filterStatus = '';
        // this.filter = {};
        sessionStorage.clear();

        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
        this.table.api().ajax.reload();
        this.loadAllStatus(this.filter);
      }
    }
  },
  components: {
    UbahKategori,
    FormClosingClaim,
    FormClosingClaimCourier,
  },
  created: function () {
    // console.log("filter: ",this.$route.params);
    this.roles = this.$route.meta.roles;
    this.userInfo = auth.user();
    if(this.$route.params.filter) {
      this.filter = this.$route.params.filter;
      this.loadAllStatus(this.filter);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
        this.filter.claim = '';
        this.loadAllStatus(this.filter);
    }

    if(this.filter.dt1 && this.filter.dt2)
    {
      this.dt1 = moment(this.filter.dt1);
      this.dt2 = moment(this.filter.dt2);
    } else {
        this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
        this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
    }
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    if(this.filter.gid)
    {
      this.groupId = this.filter.gid;
      this.dateStart = '';
      this.dateEnd = '';
	  }
    if(Object.keys(this.filter).length<1)
    {
      if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
    }
    else{
      sessionStorage.filterData = JSON.stringify(this.filter);
      sessionStorage.filterStatus = 1;
    }
    this.filter.datatype = this.data_type;

    /**
     if (this.filter.open > 0) {
        this.filter.ticket = 'open';
    } else if (this.filter.closed > 0) {
        this.filter.ticket = 'closed';
    } else 
     */
    if (this.filter.pending > 0) {
        this.filter.claim = 'pending';
    } else if (this.filter.approve > 0) {
        this.filter.claim = 'approve';
    } else if (this.filter.not_approve > 0) {
        this.filter.claim = 'not_approve';
    } else if (this.filter.banding > 0) {
        this.filter.claim = 'banding';
    } else {
        this.ticket_type = '';
    }
    
    // this.filter.ticket = this.filter.open > 0 ? this.ticket_type == "open" : this.ticket_type == "closed";
  },
  methods: {
    reloadData: function (e) {
      this.table.api().ajax.reload(null, false);
    },
    selectStatus: function () {
        $(this.$refs.formFilter).modal("toggle");
    },
    getSpecial(event) {
        this.table.api().ajax.reload();
        $(this.$refs.formFilter).modal("toggle");
        event.preventDefault();
    },
    selectAll: function (e) {
      var val = e.target.checked;
      var checkboxes = document.querySelectorAll('.select-order');
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = val;
      }
      if (val && checkboxes.length > 0)
        this.selectedCount = checkboxes.length;
      else this.selectedCount = 0;
      if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
      else $(this.$refs.btnSelected).hide();
    },
    closeClaimReturn: function () {
      var checkboxes = document.querySelectorAll('.select-order');
      var selected = [];
      this.returnClaimCourier = true;
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          var exp = checkboxes[i].value.split(';');
          selected.push(exp[0]);
          if (exp[2] != 3) {
            this.returnClaimCourier = false;
          } else if (exp[3] == 4) {
            this.returnClaimCourier = false;
          }
        }
      }
      this.selectedItems = selected;
      this.showClosingClaim = !this.showClosingClaim;
    },
    closeClaimReturnCourier: function () {
      var checkboxes = document.querySelectorAll('.select-order');
      var selected = [];
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          var exp = checkboxes[i].value.split(';');
          this.return_courier = exp[2];
          selected.push(exp[0]);
        }
      }
      this.selectedItems = selected;
      this.showClosingClaimCourier = !this.showClosingClaimCourier;
    },
    claimReturn: function () {
      var checkboxes = document.querySelectorAll('.select-order');
      var selected = [];
      this.awalItems = '-';
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          var exp = checkboxes[i].value.split(';');
          selected.push(exp[0]);
          if (this.awalItems == '-' && i < 2) {
            this.awalItems = exp[1];
          } else if (exp[1] != this.awalItems) {
            this.awalItems = '-';
          }
        }
      }
      this.selectedItems = selected;
      this.openClaim = !this.openClaim;
    },
    onDate() {
			this.table.api().ajax.reload();
		},
    dataType(event) {
			this.data_type = event.target.value;
      this.filter.datatype = this.data_type;
			// this.table.api().ajax.reload();
		},
    getDate() {
			const e = this.$refs;
			const self = this;
			authFetch("/ticket/claim/last_date").then(res => {
				res.json().then(json => {
          new Daterangepicker(
          this.$refs.daterange,
          {
            startDate: !json ? moment().startOf("month") : moment(json).startOf("month"),
            endDate: !json ? moment().endOf("month") : moment(json).endOf("month"),
          },
          function (d1, d2) {
            self.dateStart = d1.format("YYYY-MM-DD");
            self.dateEnd = d2.format("YYYY-MM-DD");
            self.data_type = self.data_type;
            self.filter.datatype = self.data_type;
            // self.filter.ticket = self.ticket_type;
            // self.filter.claim = self.claim_type;
            self.filter.dt1 = self.dateStart;
            self.filter.dt2 = self.dateEnd;
            self.onDate();
            var json = { dt1: self.filter.dt1, dt2: self.filter.dt2, ticket: self.filter.ticket, claim: self.filter.claim };
            self.loadAllStatus(json);
          }
        );
        var role_id = this.userInfo.role_id;
        var info = auth.user();
        this.table = createTable(e.tblticket, {
          title: "List Ticket",
          roles: this.$route.params.roles,
          ajax: "/ticket/claim/arsip_ticket_claim",
          frame: false,
          scrollX: true,
          autoWidth:false,
          processing: true,
          language: {
            processing: '<i class="fa fa-spinner fa-spin" style="font-size:50px;color:black;"></i>'},
          selectedRowClass: "",
          toolbarEl: e.toolbar,
          lengthChange: true,
          filter: true,
          filterBy: [1, 2, 3, 11],
          stateSave: true,
          paramData: function (d) {
            var filter = JSON.parse(JSON.stringify(self.filter));
            for (const key in filter) {
                d[key] = filter[key] === false ? 0: filter[key];
            }
          },
          "order": [
            [7, 'desc']
          ],
          columns: [
            {
              sortable: false,
              "data": "checkall", // can be null or undefined
              "defaultContent": '<input type="checkbox">',
              render: function (data, type, row, meta) {
                // console.log(info);
                if ((row.status_claim == '1' || row.status_claim == '4') && info.role_id < 12) {
                  return (
                    '<input type="checkbox" class="select-order" value="' + row.id + ';' + row.categoryName + ';' + row.return_claim_courier + ';' + row.status_claim + '" />'
                  );
                } else {
                  return ('');
                }
              },
            },
            {
              data: "no_resi",
              render: function (data, type, row, meta) {
                return `<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${data}</span>`;
              },
            },
            { data: "title" },
            { data: "sender_name" },
            {
              data: "categoryName",
              // render: function (data, type, row, meta) {
              //   var cls = 'secondary';
              //   if(data=='High')cls='danger';
              //   else if(data=='Urgent')cls='warning';
              //   return (
              //     "<span class='badge badge-"+cls+"'>" + row.categoryName + "</span>"
              //   );
              // },
            },
            {
              data: "status_claim",
              render: function (data, type, row, meta) {
                var status = "";
                if (data == 1) {
                  status += '<span class="badge badge-info">Pending</span>';
                } else if (data == 2) {
                  status += '<span class="badge badge-success">Approved</span>';
                } else if (data == 3) {
                  status += '<span class="badge badge-danger">Not Approved</span>';
                } else if (data == 4) {
                  status += '<span class="badge badge-warning">Banding</span>';
                } else if (data == 5) {
                  status += '<span class="badge bg-indigo">Void Claim</span>';
                }

                if (role_id < 11) {
                  if (row.return_claim_courier == 2) {
                    status += ' <span class="badge badge-success">Approved by Courier</span>';
                  } else if (row.return_claim_courier == 3) {
                    status += ' <span class="badge badge-warning">Not Approved by Courier</span>';
                  }
                }

                if (row.orderStatus == 2) {
                  status += ' <span class="badge bg-indigo">Void</span>';
                } else {
                  if (row.trackingStatus == 0 && row.pickupStatus == 1) {
                    status += ' <span class="badge badge-secondary">Menunggu Pickup</span>';
                  } else if (row.trackingStatus == 1) {
                    status += ' <span class="badge badge-warning">Proses</span>';
                  } else if (row.trackingStatus == 2) {
                    status += ' <span class="badge badge-success">Terkirim</span>';
                  } else if (row.trackingStatus == 3) {
                    status += ' <span class="badge badge-warning">Proses Retur</span>';
                  } else if (row.trackingStatus == 4) {
                    if (row.returnReceived == 0) {
                      status += ' <span class="badge badge-info">Retur Diserahkan</span>';
                    } else {
                      status += ' <span class="badge badge-success">Retur Diserahkan</span>';
                    }
                  } else if (row.trackingStatus == 5) {
                    if (row.problem_description != null){
                      status += ' <span class="badge badge-danger">Paket Bermasalah - '+row.problem_description+'</span>';
                    } else {
                      status += ' <span class="badge badge-danger">Paket Bermasalah</span>';
                    }
                  }
                }

                if (row.special_claim > 0) {
                  status += '<span style="border-width: thin;border-style: solid;" class="badge border-' + (row.special_claim == 1 ? 'info text-info">Request' : row.special_claim == 2 ? 'success text-success">Approved' : row.special_claim == 3 ? 'danger text-danger">Rejected' : row.special_claim == 4 ? 'warning text-warning">Banding' : 'dark text-dark">Unknown') + ' Special Claim</span>';
                }

                return status;
              },
            },
            { data: "pickup_time" },
            { data: "claim_created" },
            { data: "claim_done" },
            // { data: "totalComment", class:'text-center', render: function (data, type, row, meta) {
            //   var clsTxt = '';
            //   var clsIcn = 'far fa-comment';
            //   let tooltipTxt = row.replied ? 'Sudah dibaca, Belum terjawab' : 'Sudah dibaca, Sudah terjawab';

            //   if(row.totalComment>1){
            //       clsIcn = 'far fa-comments';
            //       if (row.replied) {
            //           tooltipTxt = 'Sudah dibaca, Belum terjawab';
            //       }
            //   }
            //   if(row.newUpdate && row.me_last!=1){
            //     tooltipTxt = row.status == 2 ? 'Belum dibaca, Tidak terjawab' : 'Belum dibaca, Belum terjawab';
            //     clsTxt='text-primary';
            //     clsIcn = 'fa fa-comment';
            //     if(row.totalComment>1)clsIcn = 'fa fa-comments';
            //   }
            //   if(row.newUpdate){
            //     //
            //   }
            //   var replied = !row.replied?'':' <i class="fa fa-question"></i> ';
            //   return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+row.ref_code+'" data-toggle="tooltip" data-html="true" title="<h6><strong>'+tooltipTxt+'</strong></h6>"><span class="'+clsTxt+'"><i class="'+clsIcn+'"></i></span> '+data+replied+'</div>');
            // } },
            {
              data: "ref_code",
              render: function (data, type, row, meta) {
                return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+data+'" data-html="true"><span class="text-primary"><i class="fa fa-comment"></i></span></div>');
              }
            },
            {
              data: "aging",
              render: function (data, type, row, meta) {
                if (data) {
                  return (data.split(" ")[0]+' Hari');
                } else {
                  return '0 Hari';
                }
              }
            },
            {
              data: "kurir"
            },
            {
              data: "marking",
              visible: info.role_id < 12 ? true : false,
            }
            // {
            //   data: "ref_code",
            //   sortable:false,
            //   // visible: isShipper ? false : true,
            //   render: function (data, type, row, meta) {
            //     if (row.status_claim == 1) {
            //       return (
            //         `<button type="button" class="ml-2 btn btn-sm btn-danger" @click="claimReturn" :disabled="deleting"><i class="fa fa-exclamation-circle"></i> Claim</button>
            //          <button type="button" class="ml-2 btn btn-sm btn-success" @click="closeClaimReturn" :disabled="deleting">Proses</button>`
            //       );
            //     } else {
            //       return "-";
            //     }
            //   },
            // },
          ],
          createdRow: function (row, data, dataIndex) {
              if (data['special_claim'] == 1) {
                  $(row).css('background-color', '#F7EBEB');
              }
          },
          rowCallback: function (row, data) {},
          drawCallback: function () {
              $(document).ready(function(){
                $('[data-toggle="tooltip"]').tooltip();   
            });
          },
          initComplete: function () {
            $('.loading-overlay').removeClass('show');
          }
        });
        });
			});
    },
    claimType(event) {
      sessionStorage.clear();
      let self = this;
      $('#claim_courier option').prop('selected', function() {
        return this.defaultSelected;
      });
      self.filter = {};
      self.claim_type = event.target.value;
      self.filter.claim = self.claim_type;
      self.filter.dt1 = self.dateStart;
      self.filter.dt2 = self.dateEnd;
      self.filter.datatype = self.data_type;
      console.log(self.filter.claim);
      self.onDate();
      self.loadAllStatus(self.filter);
      // self.table.api().ajax.reload();
    },
    claimCourierType(event) {
      sessionStorage.clear();
      let self = this;
      $('#claim option').prop('selected', function() {
        return this.defaultSelected;
      });
      self.filter = {};
      self.claim_courier_type = event.target.value;
      self.filter.claim = self.claim_courier_type;
      self.filter.dt1 = self.dateStart;
      self.filter.dt2 = self.dateEnd;
      self.filter.datatype = self.data_type;
      console.log(self.filter.ticket);
      self.onDate();
      self.loadAllStatus(self.filter);
      // self.table.api().ajax.reload();
    },
    loadAllStatus(params) {
        /*
        if (params == 'open' || params == 'closed'){
        var ticket = { ticket: params, dt1: this.dateStart, dt2: this.dateEnd };
        data = new URLSearchParams(ticket);
      } else 
        */
      // console.log("params ", Object.keys(params));
      this.loading = true;
      var data = '';
      if (!params) {
        data = '';
      } else {
        data = Object.keys(params)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
          )
          .join("&");
      }
      // console.log(data);
      let url = '';
      // let url = '';
      if (params) {
        url = "/ticket/claim/arsip_all_tickets_claims?" + data;
      } else {
        url = "/ticket/claim/arsip_all_tickets_claims";
      }

        authFetch(url)
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
            this.allTicket = js.data;
            this.loading = false;
            console.log(js.data);
        });
    },
    downloadXlsx: function (e) {
			this.downloading = true;
			var filter = JSON.parse(JSON.stringify(this.filter));
			console.log(filter)
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");

      var table = $('#tblticket').DataTable();
			var length = table.page.info().recordsTotal;
			
			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.downloading = false;
				return false;
			} else {
        authFetch("/ticket/claim/arsip_download", {
					method: "POST",
					body: data,
				})
				.then((response) => response.blob())
				.then((blob) => {
					setTimeout(() => {
						this.downloading = false;
					}, 1000);
					var url = window.URL.createObjectURL(blob);
					var a = document.createElement("a");
					a.href = url;
					a.download = "claim.xlsx";
					document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
					a.click();
					a.remove(); //afterwards we remove the element again
				});
      }
		},
    handleClick(e) {
      if (e.target.matches("input[type=checkbox]")) {
        var checkboxes = document.querySelectorAll('.select-order:checked');
        if (checkboxes.length == 0) {
          this.$refs.checkall.checked = false;
        } else {
          this.$refs.checkall.checked = true;
        }
        this.selectedCount = checkboxes.length;
        if (this.selectedCount > 0) $(this.$refs.btnSelected).show();
        else $(this.$refs.btnSelected).hide();
        return false;
      }
      if (e.target.matches(".link-order")) {
          let route = this.$router.resolve({
                path: "/arsip/detail/" + e.target.dataset.id
            });
            window.open(route.href,"_blank");
        // return false;
      }
      if(e.target.closest('.btn-comment')) {
        //this.isShowTicket = !this.isShowTicket;
        var el = e.target.closest('.btn-comment');
        console.log(el)
        this.$root.$emit("openTicketCommentArsip", el.dataset.id);
        return false;
      }
      if (e.target.matches("button")) {
        if (e.target.dataset.action == "change") {
          this.form.status = e.target.dataset.status;
          Swal.fire({
            title:
              e.target.dataset.status == 3
                ? "Pending?"
                : e.target.dataset.status == 2
                ? "Closed?"
                : "Open?",
            showCancelButton: true,
            confirmButtonText: `Ya`,
            cancelButtonText: "Tidak",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              var data = Object.keys(this.form)
                .map(
                  (key) =>
                    encodeURIComponent(key) +
                    "=" +
                    encodeURIComponent(this.form[key])
                )
                .join("&");

              authFetch("/ticket/history/" + e.target.dataset.id, {
                method: "PUT",
                body: data,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  if (js.success) {
                    Swal.fire("Proses Berhasil", ``, "success");
                    this.filter.dt1 = this.dateStart;
                    this.filter.dt2 = this.dateEnd;
                    this.table.api().ajax.reload();
                    this.loadAllStatus(this.filter);
                  } else {
                    Swal.fire("Proses gagal", ``, "error");
                    this.table.api().ajax.reload();
                  }
                });
            }
          });
        }
        console.log(e.target);
        return false;
      }
    },
  },
  mounted() {
		this.$gtag.event(`page_view`, {
			'event_name': 'Klaim Paket'
		});
    const e = this.$refs;
    const self = this;
    this.$root.$on('reloadDashboardTicket', () => {
        this.loadAllStatus();
        this.table.api().ajax.reload( null, false ); // user paging is not reset on reload
    })

    this.getDate();

    // new Daterangepicker(
    //   this.$refs.daterange,
    //   {
    //     startDate: !this.dt1 ? moment().day(-31) : this.dt1,
    //     endDate: !this.dt2 ? moment() : this.dt2,
    //   },
    //   function (d1, d2) {
    //     self.dateStart = d1.format("YYYY-MM-DD");
    //     self.dateEnd = d2.format("YYYY-MM-DD");
    //     self.data_type = self.data_type;
    //     self.filter.datatype = self.data_type;
    //     // self.filter.ticket = self.ticket_type;
    //     // self.filter.claim = self.claim_type;
    //     self.filter.dt1 = self.dateStart;
    //     self.filter.dt2 = self.dateEnd;
    //     self.onDate();
    //     var json = { dt1: self.filter.dt1, dt2: self.filter.dt2, ticket: self.filter.ticket, claim: self.filter.claim };
    //     self.loadAllStatus(json);
    //   }
    // );
    // var role_id = this.userInfo.role_id;
    // var info = auth.user();
    // this.table = createTable(e.tblticket, {
    //   title: "List Ticket",
    //   roles: this.$route.params.roles,
    //   ajax: "/ticket/claim/arsip_ticket_claim",
    //   frame: false,
    //   scrollX: true,
    //   autoWidth:false,
    //   processing: true,
    //   language: {
    //     processing: '<i class="fa fa-spinner fa-spin" style="font-size:50px;color:black;"></i>'},
    //   selectedRowClass: "",
    //   toolbarEl: e.toolbar,
    //   lengthChange: true,
    //   filter: true,
    //   filterBy: [1, 2, 3, 11],
    //   stateSave: true,
    //   paramData: function (d) {
    //     var filter = JSON.parse(JSON.stringify(self.filter));
    //     for (const key in filter) {
    //         d[key] = filter[key] === false ? 0: filter[key];
    //     }
    //   },
		// 	"order": [
		// 		[7, 'desc']
		// 	],
    //   columns: [
    //     {
    //       sortable: false,
    //       "data": "checkall", // can be null or undefined
    //       "defaultContent": '<input type="checkbox">',
    //       render: function (data, type, row, meta) {
    //         // console.log(info);
    //         if ((row.status_claim == '1' || row.status_claim == '4') && info.role_id < 12) {
    //           return (
    //             '<input type="checkbox" class="select-order" value="' + row.id + ';' + row.categoryName + ';' + row.return_claim_courier + ';' + row.status_claim + '" />'
    //           );
    //         } else {
    //           return ('');
    //         }
    //       },
    //     },
    //     {
    //       data: "no_resi",
    //       render: function (data, type, row, meta) {
    //         return `<span class="link link-order text-info" style="cursor:pointer;" data-id="${row.order_no}">${data}</span>`;
    //       },
    //     },
    //     { data: "title" },
    //     { data: "sender_name" },
    //     {
    //       data: "categoryName",
    //       // render: function (data, type, row, meta) {
    //       //   var cls = 'secondary';
    //       //   if(data=='High')cls='danger';
    //       //   else if(data=='Urgent')cls='warning';
    //       //   return (
    //       //     "<span class='badge badge-"+cls+"'>" + row.categoryName + "</span>"
    //       //   );
    //       // },
    //     },
    //     {
    //       data: "status_claim",
    //       render: function (data, type, row, meta) {
    //         var status = "";
    //         if (data == 1) {
    //           status += '<span class="badge badge-info">Pending</span>';
    //         } else if (data == 2) {
    //           status += '<span class="badge badge-success">Approved</span>';
    //         } else if (data == 3) {
    //           status += '<span class="badge badge-danger">Not Approved</span>';
    //         } else if (data == 4) {
    //           status += '<span class="badge badge-warning">Banding</span>';
    //         } else if (data == 5) {
    //           status += '<span class="badge bg-indigo">Void Claim</span>';
    //         }

    //         if (role_id < 11) {
    //           if (row.return_claim_courier == 2) {
    //             status += ' <span class="badge badge-success">Approved by Courier</span>';
    //           } else if (row.return_claim_courier == 3) {
    //             status += ' <span class="badge badge-warning">Not Approved by Courier</span>';
    //           }
    //         }

    //         if (row.orderStatus == 2) {
    //           status += ' <span class="badge bg-indigo">Void</span>';
    //         } else {
    //           if (row.trackingStatus == 0 && row.pickupStatus == 1) {
    //             status += ' <span class="badge badge-secondary">Menunggu Pickup</span>';
    //           } else if (row.trackingStatus == 1) {
    //             status += ' <span class="badge badge-warning">Proses</span>';
    //           } else if (row.trackingStatus == 2) {
    //             status += ' <span class="badge badge-success">Terkirim</span>';
    //           } else if (row.trackingStatus == 3) {
    //             status += ' <span class="badge badge-warning">Proses Retur</span>';
    //           } else if (row.trackingStatus == 4) {
    //             if (row.returnReceived == 0) {
    //               status += ' <span class="badge badge-info">Retur Diserahkan</span>';
    //             } else {
    //               status += ' <span class="badge badge-success">Retur Diserahkan</span>';
    //             }
    //           } else if (row.trackingStatus == 5) {
    //             if (row.problem_description != null){
    //               status += ' <span class="badge badge-danger">Paket Bermasalah - '+row.problem_description+'</span>';
    //             } else {
    //               status += ' <span class="badge badge-danger">Paket Bermasalah</span>';
    //             }
    //           }
    //         }

    //         if (row.special_claim > 0) {
    //           status += '<span style="border-width: thin;border-style: solid;" class="badge border-' + (row.special_claim == 1 ? 'info text-info">Request' : row.special_claim == 2 ? 'success text-success">Approved' : row.special_claim == 3 ? 'danger text-danger">Rejected' : row.special_claim == 4 ? 'warning text-warning">Banding' : 'dark text-dark">Unknown') + ' Special Claim</span>';
    //         }

    //         return status;
    //       },
    //     },
    //     { data: "pickup_time" },
    //     { data: "claim_created" },
    //     { data: "claim_done" },
    //     // { data: "totalComment", class:'text-center', render: function (data, type, row, meta) {
    //     //   var clsTxt = '';
    //     //   var clsIcn = 'far fa-comment';
    //     //   let tooltipTxt = row.replied ? 'Sudah dibaca, Belum terjawab' : 'Sudah dibaca, Sudah terjawab';

    //     //   if(row.totalComment>1){
    //     //       clsIcn = 'far fa-comments';
    //     //       if (row.replied) {
    //     //           tooltipTxt = 'Sudah dibaca, Belum terjawab';
    //     //       }
    //     //   }
    //     //   if(row.newUpdate && row.me_last!=1){
    //     //     tooltipTxt = row.status == 2 ? 'Belum dibaca, Tidak terjawab' : 'Belum dibaca, Belum terjawab';
    //     //     clsTxt='text-primary';
    //     //     clsIcn = 'fa fa-comment';
    //     //     if(row.totalComment>1)clsIcn = 'fa fa-comments';
    //     //   }
    //     //   if(row.newUpdate){
    //     //     //
    //     //   }
    //     //   var replied = !row.replied?'':' <i class="fa fa-question"></i> ';
    //     //   return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+row.ref_code+'" data-toggle="tooltip" data-html="true" title="<h6><strong>'+tooltipTxt+'</strong></h6>"><span class="'+clsTxt+'"><i class="'+clsIcn+'"></i></span> '+data+replied+'</div>');
    //     // } },
    //     {
    //       data: "ref_code",
    //       render: function (data, type, row, meta) {
    //         return ('<div class="btn-comment" style="cursor:pointer;" data-id="'+data+'" data-html="true"><span class="text-primary"><i class="fa fa-comment"></i></span></div>');
    //       }
    //     },
    //     {
    //       data: "aging",
    //       render: function (data, type, row, meta) {
    //         if (data) {
    //           return (data.split(" ")[0]+' Hari');
    //         } else {
    //           return '0 Hari';
    //         }
    //       }
    //     },
    //     {
    //       data: "kurir"
    //     },
    //     {
    //       data: "marking",
    //       visible: info.role_id < 12 ? true : false,
    //     }
    //     // {
    //     //   data: "ref_code",
    //     //   sortable:false,
    //     //   // visible: isShipper ? false : true,
    //     //   render: function (data, type, row, meta) {
    //     //     if (row.status_claim == 1) {
    //     //       return (
    //     //         `<button type="button" class="ml-2 btn btn-sm btn-danger" @click="claimReturn" :disabled="deleting"><i class="fa fa-exclamation-circle"></i> Claim</button>
    //     //          <button type="button" class="ml-2 btn btn-sm btn-success" @click="closeClaimReturn" :disabled="deleting">Proses</button>`
    //     //       );
    //     //     } else {
    //     //       return "-";
    //     //     }
    //     //   },
    //     // },
    //   ],
    //   createdRow: function (row, data, dataIndex) {
    //       if (data['special_claim'] == 1) {
    //           $(row).css('background-color', '#F7EBEB');
    //       }
    //   },
    //   rowCallback: function (row, data) {},
    //   drawCallback: function () {
    //       $(document).ready(function(){
    //         $('[data-toggle="tooltip"]').tooltip();   
    //     });
    //   },
    //   initComplete: function () {
    //     $('.loading-overlay').removeClass('show');
    //   }
    // });
  },
};
</script>
<style>
.table-ticket .dataTables_scrollBody{min-height: 100px}
</style>